import React, {
  useEffect,
  useRef,
  createContext,
  useContext,
  useState,
} from "react";

// Create context for managing popup stack
const PopupContext = createContext<{
  registerPopup: (id: string) => void;
  unregisterPopup: (id: string) => void;
  activePopups: string[];
  getTopPopupId: () => string | null;
}>({
  registerPopup: () => {},
  unregisterPopup: () => {},
  activePopups: [],
  getTopPopupId: () => null,
});

export const PopupProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activePopups, setActivePopups] = useState<string[]>([]);

  const registerPopup = (id: string) => {
    setActivePopups((prev) => [...prev, id]);
  };

  const unregisterPopup = (id: string) => {
    setActivePopups((prev) => prev.filter((popupId) => popupId !== id));
  };

  const getTopPopupId = () => {
    return activePopups[activePopups.length - 1] || null;
  };

  return (
    <PopupContext.Provider
      value={{ registerPopup, unregisterPopup, activePopups, getTopPopupId }}
    >
      {children}
    </PopupContext.Provider>
  );
};

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  id: string;
}

export const Popup: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  title,
  children,
  id,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const { registerPopup, unregisterPopup, activePopups, getTopPopupId } =
    useContext(PopupContext);

  useEffect(() => {
    if (isOpen) {
      registerPopup(id);
    } else {
      unregisterPopup(id);
    }
    return () => unregisterPopup(id);
  }, [isOpen, id]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        // Only close if this is the top-most popup
        const topPopupId = getTopPopupId();
        if (topPopupId === id) {
          onClose();
        }
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose, id, getTopPopupId]);

  if (!isOpen) return null;

  const popupIndex = activePopups.indexOf(id);
  const totalPopups = activePopups.length;

  // Calculate z-index to ensure proper stacking
  const zIndex = 1000 + popupIndex;

  return (
    <div
      ref={popupRef}
      className={`fixed left-1/2 top-1/2 transform -translate-x-1/2 
        max-w-md w-[90%] transition-all duration-300 pointer-events-auto`}
      style={{
        zIndex,
        opacity: Math.max(1 - (totalPopups - 1 - popupIndex) * 0.1, 0.5),
        transform: `translate(-50%, -50%) translateY(${
          (totalPopups - 1 - popupIndex) * 32
        }px) scale(${Math.max(
          1 - (totalPopups - 1 - popupIndex) * 0.05,
          0.9
        )})`,
      }}
    >
      <div
        className="bg-green-800/90 text-green-100 rounded-lg p-6
        border-2 border-green-400 shadow-lg shadow-green-900/50
        backdrop-blur-sm animate-fadeIn relative"
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-green-300 hover:text-white
            w-8 h-8 flex items-center justify-center rounded-full
            hover:bg-green-800 transition-colors"
        >
          ×
        </button>
        <div className="border-b border-green-400 mb-3">
          <h2 className="text-xl font-medium mb-2">{title}</h2>
        </div>
        <div className="text-sm leading-relaxed">{children}</div>
      </div>
    </div>
  );
};
