import { useState } from "react";
import "./App.css";
import MatrixRain from "./components/MatrixRain";
import { Popup, PopupProvider } from "./components/Popup";

const X = "https://x.com/KanyAITheGreat";
const PUMP_FUN =
  "https://pump.fun/profile/DWovbkz1URXfm88fK9rCegG7fqnqwH8JxPLKwJhoJk98";
const HOW_It_WORKS = "#";

interface FormState {
  username: string;
  text: string;
  isSubmitting: boolean;
  error: string | null;
}

function App() {
  // Form stuff

  const [formState, setFormState] = useState<FormState>({
    username: "",
    text: "",
    isSubmitting: false,
    error: null,
  });

  const handleSubmit = async () => {
    // Reset error state
    setFormState((prev) => ({ ...prev, isSubmitting: true, error: null }));

    try {
      const response = await fetch(
        "https://api.kanyaiwest.xyz/submissions/new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            twitterUsername: formState.username,
            message: formState.text,
          }),
        }
      );

      if (!response.ok) {
        const body = await response.json();
        throw new Error(body?.message.join(", ") || "Something went wrong");
      }

      // Reset form
      setFormState((prev) => ({
        ...prev,
        username: "",
        text: "",
        isSubmitting: false,
      }));

      // Open X (Twitter) in a new tab
      window.open("https://x.com/KanyAITheGreat", "_blank");
    } catch (err) {
      setFormState((prev) => ({
        ...prev,
        isSubmitting: false,
        error: err instanceof Error ? err.message : "Something went wrong",
      }));
    }
  };

  const [popups, setPopups] = useState({
    popup1: false,
    popup2: false,
    popup3: false,
  });

  const openPopups = () => {
    setPopups({
      popup1: true,
      popup2: true,
      popup3: true,
    });
  };

  return (
    <div>
      <MatrixRain />
      <PopupProvider>
        <div>
          <Popup
            id="popup"
            isOpen={popups.popup1}
            onClose={() => setPopups((prev) => ({ ...prev, popup1: false }))}
            title="KanyAI Receives Your Message"
          >
            Our servers forward your message to KanyAI, an advanced AI model
            with the ability to think based on your inputs. Each message tweaks
            KanyAI's personality.
          </Popup>
          <Popup
            id="popup1"
            isOpen={popups.popup2}
            onClose={() => setPopups((prev) => ({ ...prev, popup2: false }))}
            title="You Tell KanyAI"
          >
            Input your username and message to Kanye. Eachmessage sent slightly
            adjusts KanyAI’s personality, influencing the nature of the tweets
            that he posts.He may even cross reference your username if you’re
            cool
          </Popup>
          <Popup
            id="popup2"
            isOpen={popups.popup3}
            onClose={() => setPopups((prev) => ({ ...prev, popup3: false }))}
            title="KanyAI on X"
          >
            KanyAi has created a coin named $KANYAI, and needs your help to make
            it known. Tweets that KanyAI posts will all be influenced and based
            on the messages he receives from here.Try it out!
          </Popup>
        </div>
      </PopupProvider>

      <div className="relative z-10 flex items-center flex-col justify-center w-full mx-auto px-4 max-w-[120rem] sm:px-6 md:px-8 min-h-screen">
        <div className="max-w-[34.5625rem] w-full mt-6 mr-6 min-h-[3rem] rounded-[1.875rem] flex items-center px-10 justify-between bg-black ml-auto max-sm:absolute max-sm:top-0 max-sm:px-5 max-sm:min-h-[2.5rem] max-sm:left-4 max-sm:max-w-[calc(100%-2rem)]">
          <a
            href={X}
            target="_blank"
            className="sm:text-[1.25rem] text-[.75rem] text-[#00DA00] hover:underline font-light"
            rel="noreferrer"
          >
            X ACCOUNT
          </a>
          <a
            href={PUMP_FUN}
            target="_blank"
            className="sm:text-[1.25rem] text-[.75rem] text-[#00DA00] hover:underline font-light"
            rel="noreferrer"
          >
            PUMP.FUN
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              openPopups();
            }}
            target="_blank"
            className="cursor-pointer sm:text-[1.25rem] text-[.75rem] text-[#00DA00] hover:underline font-light"
            rel="noreferrer"
          >
            HOW IT WORKS?
          </a>
        </div>
        <img
          src={require("./assets/West.png")}
          className="w-full max-w-[20rem] sm:max-w-[25rem] md:max-w-[30rem] pt-[4vh] md:pt-[4vh] px-4"
          alt="west"
        />
        <input
          value={formState.username}
          onChange={(e) =>
            setFormState((prev) => ({ ...prev, username: e.target.value }))
          }
          placeholder="X Username...."
          className="bg-white rounded-[1.875rem] w-full max-w-[90%] sm:max-w-[29.0625rem] 
            min-h-[3rem] text-base sm:text-xl px-4 sm:px-5 border-none outline-none 
            mt-[4vh] sm:mt-[7vh] mx-4"
        />
        <div className="sm:text-[2.3125rem] max-w-[90%] text-[1.125rem] text-center text-[#16C21C] mt-[2vh] sm:mt-[4vh] shadow-custom">
          INFLUENCE KANYAI BY TELLING HIM ANYTHING YOU WANT
        </div>
        <div className="relative w-full max-w-[90%] sm:max-w-[42.875rem] md:max-w-[52.875rem] mx-4">
          <div className="relative">
            <textarea
              value={formState.text}
              onChange={(e) =>
                setFormState((prev) => ({ ...prev, text: e.target.value }))
              }
              placeholder="Tell KanyAI what you want. Each submission will change his personality....."
              className="bg-white rounded-[1.875rem] w-full min-h-[10rem] sm:min-h-[14.375rem] 
            border-none outline-none text-base sm:text-xl p-4 sm:p-5 
            mt-[2vh] sm:mt-[1vh] mb-[3vh] sm:mb-[5vh]
            pr-24 sm:pr-28" // Added padding to prevent text from going under button
            />
            <button
              onClick={handleSubmit}
              disabled={
                formState.isSubmitting || !formState.username || !formState.text
              }
              className={`
    absolute bottom-[4.5vh] sm:bottom-[6.5vh] right-4 sm:right-5
    text-white px-6 py-2 rounded-full
    transition-all duration-200
    font-medium text-sm sm:text-base
    ${
      formState.isSubmitting || !formState.username || !formState.text
        ? "bg-[#65A30D]/50 cursor-not-allowed opacity-50 hover:bg-[#65A30D]/50"
        : "bg-[#65A30D] hover:bg-[#4B7B0A] cursor-pointer"
    }
  `}
            >
              {formState.isSubmitting ? "SENDING..." : "SUBMIT"}
            </button>
          </div>
        </div>
        {formState.error && (
          <div className="text-red-500 text-sm mt-1 mb-2 text-center">
            {formState.error}
          </div>
        )}
        <button
          onClick={() => openPopups()}
          className="rounded-[1.875rem] px-4 sm:px-[1.375rem] py-3 sm:py-4 
          w-max bg-[#D9D9D9] transition-all hover:bg-white border-none 
          outline-none text-base sm:text-xl"
        >
          Who is KanyAI?
        </button>
      </div>
    </div>
  );
}

export default App;
