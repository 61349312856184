import { useEffect, useRef, useState } from "react";

interface MatrixRainProps {
  speed?: number;
}

const MatrixRain = ({ speed = 2.5 }: MatrixRainProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    let animationFrameId: number;
    const drops: number[] = [];
    const dropSpeeds: number[] = [];

    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    const fontSize = 16;
    const spacing = 10;
    const columnWidth = fontSize + spacing;
    const columns = Math.floor(dimensions.width / columnWidth);

    // Convert hex to RGB for gradient
    const startColor = {
      r: 0, // from #003E0D
      g: 62,
      b: 13,
    };

    for (let i = 0; i < columns; i++) {
      drops[i] = 1;
      dropSpeeds[i] = (Math.random() * 0.2 + 0.1) * speed;
    }

    let lastTime = 0;
    const fps = 30;
    const interval = 1000 / fps;

    const animate = (currentTime: number) => {
      animationFrameId = window.requestAnimationFrame(animate);
      const deltaTime = currentTime - lastTime;
      if (deltaTime < interval) return;
      lastTime = currentTime - (deltaTime % interval);

      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, dimensions.width, dimensions.height);
      ctx.font = `${fontSize}px monospace`;
      ctx.textAlign = "center";

      for (let i = 0; i < drops.length; i++) {
        const text = Math.random() > 0.5 ? "1" : "0";
        const y = drops[i] * fontSize;
        const x = i * columnWidth + columnWidth / 2;

        // Calculate position-based alpha and color
        const normalizedY = y / dimensions.height;
        const alpha = Math.max(0, 1 - normalizedY);

        // Create gradient from startColor to transparent
        const r = Math.floor(startColor.r * alpha);
        const g = Math.floor(startColor.g + (255 - startColor.g) * (1 - alpha));
        const b = Math.floor(startColor.b * alpha);

        // Use brighter green for characters near the top
        if (normalizedY < 0.2) {
          ctx.fillStyle = `rgba(0, 255, 0, ${alpha})`;
        } else {
          ctx.fillStyle = `rgba(${r}, ${g}, ${b}, ${alpha})`;
        }

        ctx.fillText(text, x, y);

        if (y > dimensions.height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        drops[i] += dropSpeeds[i];
      }
    };

    animate(0);

    return () => {
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, [dimensions, speed]);

  return (
    <div className="absolute w-screen h-screen overflow-hidden">
      <div
        className="absolute inset-0 z-10 pointer-events-none"
        style={{
          background: `linear-gradient(180deg, rgba(0, 62, 13, 0.6) 0%, rgba(0, 62, 13, 0.251) 50%, transparent 100%)`,
        }}
      />
      {/* Canvas background */}
      <div className="absolute inset-0 bg-black">
        <canvas
          ref={canvasRef}
          width={dimensions.width}
          height={dimensions.height}
          className="block"
        />
      </div>
    </div>
  );
};

export default MatrixRain;
